.contact.right-text {
    margin-top: 105px;
    padding-bottom: 50px;
    .text-block {
        border-color: $skyblue;
        h2 {
            text-decoration: underline;
        }
    }
    .custom-btn {
        background-color: $skyblue;
    }
    iframe {
        margin-top: -160px;
        width: 100%;
        height: calc(100% + 210px);
    }
    .map-info {
        position: absolute;
        padding: 20px;
        top: 30px;
        left: 30px;
    }
    a {
        color: $black;
        &:hover {
            color: $green;
        }
    }
    h6 {
        margin-bottom: 0;
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 40px;
    }
    .custom-btn {
        color: $white;
    }
}

// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
  
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
  
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
    .contact.right-text {
        margin-top: 75px;
        iframe {            
            width: 100%;
            height: 600px;
        }
        .map-info {
            top: auto;
            left: 60px;
            bottom: 30px;
        }        
    }
}