footer {
    background-color: $green;
    color: $white;
    font-size: 12px;
    padding-bottom: 20px;
    .container-fluid {
		padding: 0 60px 0 0;
    }
    a {
        color: $white;
        &:hover {
            color: $blue;
        }
    }
    .nav {
        width: 100%;
        margin: 30px 0;
        .nav-link {
            font-weight: 700;
            font-size: 14px;
        }        
    }
    .footer-logo {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        img {
            width: 65%;
        }
        p {
            font-size: 12px;
            margin-bottom: 0;
        }
    }
    .address, .disclaimer {        
        border-left: 1px solid $white;
        padding-left: 15px;
    }
    .address {
        font-size: 15px;
        word-wrap: break-word;     
    }
    .social {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        img {
            width: 20px;
            margin-bottom: 30px;
        }
    }
}

// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
    footer {
        .container-fluid {
            padding: 0;
        }
    }
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
    footer {
        font-size: 12px;
        .container-fluid {
            padding: 0;
        }
        .nav {
            margin: 15px 0;
            .nav-link {
                font-size: 12px;
                padding-top: 0;
            }        
        }
        .address {
            font-size: 13px;        
        }        
    }
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
    footer {
        font-size: 12px;
        padding-bottom: 0px;
        .container-fluid {
            padding: 0;
        }
        .nav {
            margin: 15px 0;
            .nav-link {
                font-size: 12px;
                padding-top: 0;
            }        
        }
        .address {
            font-size: 13px;        
        }        
    }
}