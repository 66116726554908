.naics {
    margin-top: 50px;
    padding: 50px;
    // padding-bottom: 0;
    background-color: $skyblue;
    color: $white;
    z-index: 10;
    .naics-body {
        // margin-bottom: 50px;
        p {
            font-weight: 400;
        }
        a {
            cursor: default;
        }
    }
    .collapse-btn-wrapper {        
        img {
            @include transition(all 0.5s ease);
            width: 60px;
            margin-bottom: -30px;
        }
    }
}

.recent-projects {
    margin-top: 50px;
    margin-bottom: 50px;
    .recent-body {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 50px 35px;
        border-radius: 20px;
        .mask {
            background-color: $black;
            opacity: 0;
            transition: all 0.3s;
            display: flex;
            color: $white;
            padding: 50px 20px;
            align-items: center;
            justify-content: center;
            text-align: center;
            img {
                width: 50px;
            }
        }
        a {
            &:hover {
                .mask {
                    transition: all 0.3s;
                    opacity: 0.7;
                }                
            }
            img, .mask {
                transition: all 0.3s;
            }
        }
    }
}


// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
    .recent-projects {        
        .recent-body {
            width: 95%;
            padding: 30px 15px;
        }
    }
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
    .naics {
        margin-top: 20px; 
        padding: 35px;
    }   
    .recent-projects {
        margin-top: 35px;
        margin-bottom: 35px;
        .recent-body {
            width: 95%;
            padding: 30px 15px;
        }
    } 
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
    .naics {
        margin-top: 20px; 
        padding: 35px;       
    }   
    .recent-projects {
        margin-top: 35px;
        margin-bottom: 35px;
        .recent-body {
            width: 95%;
            padding: 30px 15px;
            padding-bottom: 0;
            .col-md-4 {
                margin-bottom: 30px;
            }
        }
    } 
}