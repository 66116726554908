.mission {
    background-color: $skyblue;
    padding: 30px 0;
    .container-fluid {
        padding: 0 150px;
    }
    span {
        color: $white;
    }
}

// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
  
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
    .mission {
        .container-fluid {
            padding: 0 80px;
        }
    }
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
    .mission {
        .container-fluid {
            padding: 0 40px;
        }
    }
}