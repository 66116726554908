@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700');
@import "partials/variables.scss";
@import "partials/mixins.scss";
@import "ie.scss";
@import "base";
@import "header";
@import "home";
@import "about-us";
@import "contact-us";
@import "projects";
@import "services";
@import "footer";