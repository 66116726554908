.projects {
    .container-fluid {
        padding: 50px 150px;
    }
    .nav > li:first-child {
        padding-left: 0;
    }
    .nav {
        border-bottom: 1px solid grey;
        padding-bottom: 30px;     
        .nav-link {            
            color: $black;
            font-weight: 700;
            padding-left: 0;
            padding-right: 20px;
            &.active {
                text-decoration: underline;
            }            
        }
    }
    .tab-content {
        padding: 40px 0;
    }

    .gallery-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        grid-auto-rows: auto;
        > * {
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        .mask {
            background-color: $black;
            opacity: 0;
            transition: all 0.3s;
            display: flex;
            color: $white;
            padding: 50px;
            align-items: center;
            text-align: center;
            justify-content: center;
            img {
                width: 50px;
            }
        }
        a {
            &:hover {
                img {
                    transition: all 0.3s;
                    transform: scale(1.02);
                }
                .mask {
                    transition: all 0.3s;
                    transform: scale(1.02);
                    opacity: 0.7;
                }                
            }
            img, .mask {
                transition: all 0.3s;
            }
        }
    }
    .grid1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
    .grid2 {
        grid-column: 3 / 4;
        grid-row: 1;
    }
    .grid3 {
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
    .grid4 {
        grid-column: 1;
        grid-row: 3 / 5;
    }
    .grid5 {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    .grid6 {
        grid-column: 1;
        grid-row: 5 / 6;
    }
    .grid7 {
        grid-column: 2 / 4;
        grid-row: 4 / 6;
    }
    .grid8 {
        grid-column: 1;
        grid-row: 6 / 7;
    }
    .grid9 {
        grid-column: 2 / 3;
        grid-row: 6 / 7;
    }
    .grid10 {
        grid-column: 3 / 4;
        grid-row: 6 / 8;
    }
    .grid11 {
        grid-column: 1;
        grid-row: 7 / 8;
    }
    .grid12 {
        grid-column: 2 / 3;
        grid-row: 7 / 8;
    }

        .grid13 {
            grid-column: 1;
            grid-row: 1 / 3;
        }
        .grid14 {
            grid-column: 2 / 3;
            grid-row: 1;
        }
        .grid15 {
            grid-column: 3 / 4;
            grid-row: 1;
        }
        .grid16 {
            grid-column: 1;
            grid-row: 3 / 4;
        }
        .grid17 {
            grid-column: 2 / 4;
            grid-row: 2 / 4;
        }
}

.fancybox-container {
    .fancybox-caption {
        bottom: 30%;
        background: rgba(0,0,0, 0.5);
        padding: 20px 10px;
        font-size: 16px;
    }
}

.slider-section {
    margin-top: 135px;
    .carousel {
        .carousel-item {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
        .carousel-control-next-icon {
            background-image: url('../../services/images/arrow-white.png');
        }
        .carousel-control-prev-icon {
            background-image: url('../../services/images/arrow-white.png');
            transform: rotate(-180deg);
        }
    }
}

.description {
    padding-top: 50px;
    padding-bottom: 50px;
    .container-fluid {
        padding: 0 65px;
    }
    .project-header {
        width: 50%;
        max-width: 400px;
    }
    .details {
        padding: 30px;
    }
    .glance {
        background-color: $white;
        padding: 25px;
        h5 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        h6 {
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 0;
        }
        ul {
            list-style: none;
            margin: 25px 50px 0 50px;
            padding-left: 0;
            li {
                border-bottom: 1px solid grey;
                padding: 10px 0;
                span {
                    font-weight: 600;
                }
            }
        }        
    }    
}

.more {
    .container-fluid {
        padding: 0 65px;
    } 
    .more-porjects {
        border-top: 1px solid grey;
        padding: 40px 0;
        margin-top: 30px;
        h5 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
    .more-images {
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .mask {
        background-color: $black;
        opacity: 0;
        transition: all 0.3s;
        display: flex;
        color: $white;
        padding: 50px 20px;
        align-items: center;
        text-align: center;
        img {
            width: 50px;
        }
    }
    a {
        &:hover {
            .mask {
                transition: all 0.3s;
                opacity: 0.7;
            }                
        }
        img, .mask {
            transition: all 0.3s;
        }
    }
}


// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
    .projects {
        .container-fluid {
            padding: 50px;
        }
        
        .gallery-wrapper {
            .mask {
                padding: 20px;
            }
            h6 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .slider-section {
        margin-top: 123px;
    }
    .description {        
        .glance {
            h5, h6 {
                font-size: 18px;
            }
            ul {
                list-style: none;
                margin: 25px 30px 0 30px;
                padding-left: 0;                
            }        
        }        
    }
    .more {
        .mask {
            padding: 20px;
            justify-content: center;
            p {
                display: none;
            }            
        }
    }
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
    .slider-section {
        margin-top: 76px;
    }
    .projects {
        .container-fluid {
            padding: 30px;
        }
        
        .gallery-wrapper {
            .mask {
                padding: 10px;
                justify-content: center;
                img {
                    margin-top: 15px;
                }
            }
            h6 {
                font-size: 18px;
                margin-bottom: 0;
            }
            p {
                display: none;
                font-size: 12px;
            }
        }        
    }
    .slider-section {
        margin-top: 100px;
    }
    .description {
        .container-fluid {
            padding: 0 35px;
        }
        .glance {
            h5, h6 {
                font-size: 16px;
            }
            ul {
                margin: 20px;
            }        
        }        
    }
    .more {
        .container-fluid {
            padding: 0 35px;
        }
        .more-porjects {
            margin-top: 0px;
        }
        .mask {
            padding: 20px;
            justify-content: center;
            p {
                display: none;
            }
        }
    }
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
    .slider-section {
        margin-top: 76px;
    }
    .projects {
        .container-fluid {
            padding: 20px;
        }
        .nav {
            padding-bottom: 15px;
        }
        .tab-content {
            padding: 30px 0;
        }
        .gallery-wrapper {
            .mask {
                padding: 5px;
                justify-content: center;
                img {
                    margin-top: 10px;
                }
            }
            h6 {
                font-size: 14px;
                margin-bottom: 0;
            }
            p {
                display: none;
            }
        }
    }
    .slider-section {
        margin-top: 77px;
    }
    .description {
        padding-bottom: 0;
        .container-fluid {
            padding: 0 35px;
        }
        .project-header {
            width: 250px;
            max-width: none;
        }
        .glance {
            padding: 25px;
            h5, h6 {
                font-size: 16px;
            }
            img {
                max-width: 200px;
            }
            ul {
                list-style: none;
                margin: 25px 50px 0 50px;
            }        
        }        
    }
    .more {
        .container-fluid {
            padding: 0 35px;
        }
        .more-porjects {
            margin-top: 50px;
        }
        .mask {
            padding: 20px;
            p {
                display: block;
            }
        }
    }
}