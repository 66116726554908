.services {
    margin: 50px 0 0 0;    
    .nav {
        width: 100%;
        .nav-link {
            border-bottom: 1px solid grey;
            padding-left: 0;
            padding-right: 0;
            height: 55px;
            display: flex;   
            justify-content: space-between;
            align-items: center;
            &:hover {
                color: #007bff;
                font-weight: 500;
            }
            &.active {
                font-weight: 700;
            }         
            img {
                width: 19px;
                height: 16px;
            }
        }
    }
    .group-nav {
        border-bottom: 1px solid grey;
        padding: 30px 0;
        .nav-link {
            border-bottom: none;
            color: $black;
            padding-right: 50px;
            &:hover {
                color: $black;
            }
            .service-count {
                display: flex;
                margin-left: 15px;
                width: 45px;
                height: 45px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
        }
    }
    .carousel {
        .carousel-control-next-icon {
            background-image: url('../../services/images/arrow-white.png');
        }
        .carousel-control-prev-icon {
            background-image: url('../../services/images/arrow-white.png');
            transform: rotate(-180deg);
        }
    }

    .service-info {
        background-color: rgba(74,192,224, 0.45);
        padding: 30px;
        display: flex;
        align-items: center;
    }
    .tab-content {
        padding: 2rem 0;
        width: 100%;
    }
}


// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
  
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
  
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
    .services {
        margin-top: 20px;
        .group-nav {
            border-bottom: none;
            padding-bottom: 0;      
        }
        .service-info {
            margin-left: 15px;
            margin-right: 15px;
        }
        .dropdown-menu {
            max-height: 200px;
            overflow-y: scroll;
        }
        /*Visible scrollbars*/
        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
        }
        ::-webkit-scrollbar-track { 
            background: $white; 
            border-radius: 5px; 
        } 

        ::-webkit-scrollbar-thumb {
            background-color: rgba(30, 66, 152, .6);
        }
        /*end*/
    }
    
}