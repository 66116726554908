/* Typography */
html {
  width: 100%;
  //max-width: 1680px;
  background-color: grey;
  margin-left: auto;
  margin-right: auto;
  scroll-behavior: smooth;
}
body {
  background: $grey;
  font-family: 'Work Sans', sans-serif;
  color: black;  
}
h1,
.h1 {
  font-size: 70px;
  font-weight: 700;
  margin-bottom: 15px;
}
h2,
.h2 {
  font-size: 53px;
  font-weight: 700;
  margin-bottom: 40px;
}
h3,
.h3 {
}
h4,
.h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
h5,
.h5 {
  margin-bottom: 25px;
}
h6,
.h6 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
p {
}
.outline {
  display: none;
}
a {
  -webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition: color 300ms, background-color 300ms;
  transition: color 300ms, background-color 300ms;
}
p.small {
  font-variant: small-caps;
}
.vh-100 {
  height: 100vh;
}

.container-fluid {
  padding: 0 55px;
}

.img-fluid {
  align-self: flex-start;
  width: 100%;
  max-height: 800px;
}

.flip {
  img {
    @include transition(all 0.5s ease);
    transform: rotate(-180deg);
  }  
}

// Background
.bg-center {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-white {
  background-color: $white;
}
.bg-grey {
  background-color: $grey;
}
.bg-skyblue {
  background-color: $skyblue;
}
.bg-green {
  background-color: $green;
}

// Contact
#success_message {
  display: none;
  margin-top: 30px;
  text-align: center;
}

/* IE Version update TAB */
#VersionTab {
  z-index: 99999;
  position: absolute;
  top: 100px;
  color: red;
  width: 100%;
  text-align: center;
  font-size: 22px;
  background-color: yellow;
  height: 100px;
  line-height: 100px;
  a {
    color: blue;
  }
}

.mobile {
  display: none;
}
.p-relative {
  position: relative;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-image: linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.4));
}

.hero-section {
  margin-top: 135px;
  .hero-caption {
    position: absolute;
    color: $white;
    bottom: 100px;
    left: 150px;
    width: 60%;
    p {
      margin-bottom: 35px;
    }
  }
  .heroHeader {
    .letter {
      display: inline-block;
    }
  }
  
}


.custom-btn {
  box-shadow: none;
  margin: 0;
  background-color: $blue;
  padding: 15px 40px;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
}

.text-wrapper {
  padding: 40px;  
}
.text-block {
  padding: 40px;
  border: 2px solid $skyblue;
  height: 100%;
  word-wrap: break-word;
  font-weight: 400;
  p {
    margin-bottom: 40px;    
    width: 85%;
  }
  img {
    width: 25px;
  }
  .custom-btn {
    margin-top: 25px;
  }
}

.image-wrapper {
  padding: 0;
  img {
    position: relative;
    z-index: 10;
  }
}

.left-text {
  padding-top: 50px;
  .container-fluid {
      padding-right: 15px;
  }
  .text-wrapper {
      padding-right: 0;  
  }
  .text-block {
      border-right: none;
      h2 {
        span {
          display: inline;
        }
      }  
  }
  .custom-btn {
      background-color: $skyblue;
  }
}

.right-text {
  padding-top: 50px;
  .container-fluid {
      padding-left: 15px;
  }
  .text-wrapper {
      padding-left: 0;  
  }
  .text-block {
      border-color: $green;
      border-left: none;
  }
  .custom-btn {
      background-color: $green;
  }
}

.left-text, .right-text {
  .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-fit: 50% 50%;
  }
}

.p-bottom {
  padding-bottom: 50px;
}

.testimonials {
  margin-top: 60px;
  margin-bottom: 50px;
  text-align: center;
  .testimonials-body {
      background-color: $white;
      border-radius: 15px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding: 50px 50px 25px 50px;
  }
  .carousel-indicators {
      bottom: -40px;
      li {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: $black;
      }        
  }
  .carousel-item {
      p {
          font-size: 20px;
      }
      img {
          width: 25px;
          margin: 0 15px;
      }
      .name {
          text-align: right;
          font-weight: 700;
          color: $skyblue;
      }
  }
}

// Fade In Animation

/* Animation */

@keyframes fadeInRight {
  from {
    opacity: 0.3;
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0.3;
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0.3;
    -webkit-transform: translate3d(-60px, 0, 0);
    transform: translate3d(-60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0.3;
    -webkit-transform: translate3d(-60px, 0, 0);
    transform: translate3d(-60px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0.3;
    -webkit-transform: translate3d(0 ,60px, 0);
    transform: translate3d(0, 60px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0.3;
    -webkit-transform: translate3d(0 ,60px, 0);
    transform: translate3d(0, 60px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .hero-section {
    margin-top: 123px;
    .hero-caption {
      bottom: 80px;
      left: 80px;
      width: 60%;
      p {
        margin-bottom: 35px;
      }
    }
  }
  .small-header {
    h2 {
      font-size: 30px;
    }
  }
  .left-text {
    .text-block {
      h2 {
        span {
          display: none;
        }
      }  
    }
  }
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
  body {
    font-size: 14px;
  }
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .p-bottom {
    padding-bottom: 20px;
  }
  .container-fluid {
    padding: 0 35px;
  }
  .hero-section {
    margin-top: 100px;
    .hero-caption {
      bottom: 50px;
      left: 50px;
      width: 70%;
      &.small-section {
        bottom: 0px;
        left: 20px;
      }
      p {
        margin-bottom: 35px;
      }
    }
  }
  .small-header {
    h2 {
      font-size: 30px;
    }
  }

  .text-wrapper {
    padding: 20px;  
  }

  .text-block {
    padding: 20px;
    p {
      margin-bottom: 20px;
    }
  }

  .left-text {
    padding-top: 20px;    
    .container-fluid {
        padding-right: 15px;
    }
    .text-block {
      h2 {
        span {
          display: none;
        }
      }  
    }  
  }
  
  .right-text {
    padding-top: 20px;
    .container-fluid {
        padding-left: 15px;
    }    
  }
  .testimonials {
    margin-top: 45px;
    margin-bottom: 30px;      
    .carousel-item {
        p {
            font-size: 16px;
        }
        img {
            width: 20px;
        }
    }
    .testimonials-body {
      padding: 30px 30px 25px 30px;
    }
  }
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
  body {
    font-size: 14px;
  }
  h1 {
    font-size: 45px;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 24px;
  }
  h5 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .p-bottom {
    padding-bottom: 20px;
  }
  .custom-btn {
    font-size: 14px;
    padding: 10px 25px;
  }
  .container-fluid {
    padding: 0 35px;
  }
  .hero-section {
    margin-top: 75px;
    .hero-caption {
      bottom: 30px;
      left: 30px;
      width: 90%;
      &.small-section {
        bottom: 0px;
        left: 20px;
      }
      p {
        display: none;
      }
    }
  }

  .text-wrapper {
    padding: 20px;  
  }

  .text-block {
    padding: 20px;
    p {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .left-text {
    padding-top: 20px;
    .container-fluid {
        padding-right: 15px;
    }
    .text-block {
      h2 {
        span {
          display: none;
        }
      }  
    } 
  }
  
  .right-text {
    padding-top: 20px;
    .container-fluid {
        padding-left: 15px;
    }    
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  
  .testimonials {
    margin-top: 40px;
    margin-bottom: 30px;
    .container-fluid {
        padding: 0 20px;
    }       
    .carousel-item {
        p {
            font-size: 14px;
        }
        img {
            width: 15px;
        }
    }
    .testimonials-body {
      padding: 30px 30px 25px 30px;
    }
  }
}

// SMALL MOBILE
@media (max-width: 415px) {
  h1 {
    font-size: 35px;
  }
}
@media (max-width: 581px) {
  .desktop-only {
    display: none;
  }
}