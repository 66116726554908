/* Icon 1 */
.animated-icon1, .animated-icon2, .animated-icon3 {
	width: 30px;
	height: 20px;
	position: relative;
	margin: 0px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
}

.animated-icon1 span, .animated-icon2 span, .animated-icon3 span {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.animated-icon1 span {
	background: #e65100;
}

.animated-icon2 span {
	background: #e3f2fd;
}

.animated-icon3 span {
	background: $white;
}

.animated-icon1 span:nth-child(1) {
	top: 0px;
}

.animated-icon1 span:nth-child(2) {
	top: 10px;
}

.animated-icon1 span:nth-child(3) {
	top: 20px;
}

.animated-icon1.open span:nth-child(1) {
	top: 11px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
	opacity: 0;
	left: -60px;
}

.animated-icon1.open span:nth-child(3) {
	top: 11px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

/* Icon 3*/

.animated-icon2 span:nth-child(1) {
	top: 0px;
}

.animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) {
	top: 10px;
}

.animated-icon2 span:nth-child(4) {
	top: 20px;
}

.animated-icon2.open span:nth-child(1) {
	top: 11px;
	width: 0%;
	left: 50%;
}

.animated-icon2.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
	top: 11px;
	width: 0%;
	left: 50%;
}

/* Icon 4 */

.animated-icon3 span:nth-child(1) {
	top: 0px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

.animated-icon3 span:nth-child(2) {
	top: 10px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

.animated-icon3 span:nth-child(3) {
	top: 20px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

.animated-icon3.open span:nth-child(1) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 0px;
	left: 8px;
}

.animated-icon3.open span:nth-child(2) {
	width: 0%;
	opacity: 0;
}

.animated-icon3.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 21px;
	left: 8px;
}

.navbar {
	background-color: $blue;
	//max-width: 1680px;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	a {
        color: $white;
        &:hover {
            color: $green;
		}
    }
	.logo {
		width: 100px;
		margin: 10px 15px;
		@include transition(all 0.5s ease);
	}
	.navbar-brand {
		display: flex;		
		align-items: center;
		padding: 0;
		p {
			margin: 0 10px;
			font-size: 9px;
			color: $white;
			font-weight: 700;
		}
	}
	.nav-item {
		&.active {
			.nav-link {
				color: $green;
			}
		}
	}
	.nav-link {
		font-weight: 700;
		margin-left: 50px;
	}
	.container-fluid {
		padding: 0 60px;
	}	
}

.shrink {
	.navbar-brand {
		img {
			width: 75px;
			@include transition(all 0.5s ease);
		}
	}		
}

// SMALL DESKTOP
/*$screen-md:991px;$screen-lg:1200px;*/
@media (min-width: $screen-md) and (max-width: $screen-lg) {
	.navbar {
		.nav-link {
			margin-left: 30px;
		}
		.logo {
			width: 90px;
		}
	}
}

// TABLET
/*$screen-sm:767px; $screen-md:991px;*/
@media (min-width: $screen-sm) and (max-width: $screen-md) {
	.navbar {
		.container-fluid {
			padding: 0 15px;
		}
		.logo {
			width: 70px;
		}
		.navbar-brand {
			padding: 0;
			p {
				margin: 0;
				font-size: 9px;
			}
		}
		.nav-link {
			margin-left: 25px;
			font-size: 14px;
		}
	}
	.shrink {
		.navbar-brand {
			img {
				width: 60px;
				@include transition(all 0.5s ease);
			}
		}		
	}
}

// MOBILE
/*$screen-sm:767px;*/
@media (max-width: $screen-sm) {
	.navbar {
		.container-fluid {
			padding: 0 15px;
		}
		.logo {
			width: 50px;
		}
		.navbar-brand {
			padding: 0;
			p {
				margin: 0;
				font-size: 9px;
			}
		}
		.nav-item {
			text-align: center;
		}
		.nav-link {
			margin-left: 25px;
			font-size: 14px;
		}
	}
	.shrink {
		.navbar-brand {
			img {
				width: 50px;
				@include transition(all 0.5s ease);
			}
		}		
	}
}